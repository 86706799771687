import React from "react"
import { graphql, Link } from "gatsby"
import Pagination from "../components/pagination"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostMeta from "../components/postMeta"

const HomePage = props => {
    const {
        data: {
            wpgraphql: { posts },
        },
        pageContext: { pageNumber, hasNextPage },
    } = props

    const currentPage = pageNumber ? `- Page ${pageNumber}` : ``
    return (
      <Layout>
        <SEO title={`TibsBits`} />
        {!pageNumber ? (
          <div className="mb-10 mx-auto text-center max-w-4xl">
            <h1 className="text-3xl leading-tight mb-4 md:text-4xl md:leading-normal">
              Hi, this is my WordPress & Gatsby experiment
            </h1>
            <p className="text-xl mb-4">
              The content is generated using WPGraphQL to pull content from the
              WordPress install on{" "}
              <a href="https://gatsby.tiborpaulsch.nl">
                {" "}
                gatsby.tiborpaulsch.nl
              </a>
              , and it is hosted for free on Netlify :-)
            </p>
          </div>
        ) : (
          currentPage
        )}
        <div className="test">
          <h3 className="mb-4">Latest Posts</h3>
          {posts.nodes.map(post => (
            <div className="post mb-4" key={post.id}>
              <h2>
                <Link to={`/blog/${post.slug}`}>{post.title}</Link>
              </h2>
              <PostMeta post={post} />
            </div>
          ))}
          <Pagination pageNumber={pageNumber} hasNextPage={hasNextPage} />
        </div>
      </Layout>
    )
}

export default HomePage

export const pageQuery = graphql`
  query GET_FRONT_POSTS($ids: [ID]) {
    wpgraphql {
      posts(where: { in: $ids }) {
        nodes {
          id
          title
          slug
          date
        }
      }
    }
  }
`
